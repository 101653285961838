import React, { useState, useEffect } from "react"
import "./Heroe.css"
import { useRef } from "react"

export default function Heroe() {
  const [myWindow, setMyWindow] = useState(null)
  const [myScroll, setMyScroll] = useState(null)
  const heroRef = useRef(null)

  const handleResize = e => {
    setMyWindow(window.innerWidth)
  }

  const handleScroll = e => {
    setMyScroll(window.pageYOffset)
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setMyWindow(window.innerWidth)
      window.addEventListener("resize", handleResize)
      window.addEventListener("scroll", handleScroll)
    }
  })

  return (
    <section
      ref={heroRef}
      className={`${myWindow < 800 ? "set-img-bg" : "noset-img-bg"} hero`}
    >
      <video
        src="/img/hero_video.m4v"
        autoPlay
        playsinline
        muted
        loop
        id="myVideo"
        className={myWindow < 800 ? "set-invisible" : "set-visible"}
      />
      <header
        className={`${
          heroRef.current
            ? myScroll > heroRef.current.offsetHeight
              ? "header-sticky-bg"
              : "header-sticky-nobg"
            : "header-sticky"
        } header-sticky`}
      >
        <span></span>
        <nav>
          <a href="/#">HOME</a>
          <a href="/#servicios">SERVICIOS</a>
          <a href="/#contacto">CONTACTO</a>
          <a className="sticky-social" href="https://wa.me/523313671572" target="_blank" >
            <img src="/img/WhatsAppBorder.svg"></img>
          </a>
          <a className="sticky-social" href="mailto:clientes@polipack.com.mx" target="_blank">
            <img src="/img/EmailBorder.svg"></img>
          </a>
        </nav>
      </header>
      <header class="hero-header">
        <div className="hero-header-logo">
          <img src="img/Asset 24.svg" />
        </div>
        <div class="hero-title">
          <h1>
            IMPRESIÓN<br></br>DE EMPAQUE
          </h1>
          <p>FLEXOGRAFÍA · ROTOGRABADO</p>
        </div>
      </header>
      <footer class="hero-footer">
        <div className="hero-footer-info">
          <img src="img/PhoneNoBorder.svg"></img>
          <p>33 3271 0878</p>
        </div>
        <a href="mailto:clientes@polipack.com.mx" target="_blank">
        <div className="hero-footer-info">
          <img src="img/EmailNoBorder.svg"></img>
          <p>clientes@polipack.com.mx</p>
        </div>
        </a>
      </footer>
    </section>
  )
}
