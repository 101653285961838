import React from "react"

import "./Acerca.css"
import Carousel from "./Carousel"

export default function Acerca() {
  return (
    <div>
      <div className="container-acerca">
        <div className="container-acerca-blue">
          <p>
          Somos una empresa joven, Polipack nace en el
año 2008 como distribuidores de empaques
flexibles, teniendo alianzas comerciales con
distintas empresas del ramo, llegando en 2013
a ser convertidores, Polipack es una empresa
con enfoques modernos y canalizados
totalmente hacia nuestros clientes para poder
brindarles el mejor servicio, calidad y precio.
          </p>
        </div>
      </div>
      <div className="carousel-container">
        <Carousel />
        <span id="servicios"></span>
      </div>
    </div>
  )
}
