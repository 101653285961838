import React, { useState, useEffect } from "react"

import "./MyFooter.css"

export default function MyFooter() {
  const [myWindow, setMyWindow] = useState(null)

  const handleResize = e => {
    setMyWindow(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setMyWindow(window.innerWidth)
      window.addEventListener("resize", handleResize)
    }
  })
  return (
    <div className="master-footer">
      {/* 763 */}
      <div
        className={
          myWindow < 740 ? "remove-display" : "set-display blue-footer"
        }
      >
        <div className="links-footer">
          <a href="/#">HOME</a>
          <a href="/#servicios">SERVICIOS</a>
          <a href="/#contacto">CONTACTO</a>
        </div>
        <div className="vertical-separator-white"></div>
        <div className="info-footer">
          <div className="info-item">
            <img src="/img/PhoneNoBorder.svg" />
            <p>33 3271 0878</p>
          </div>
          <a href="mailto:clientes@polipack.com.mx" target="_blank">
            <div className="info-item">
              <img src="/img/EmailNoBorder.svg" />
              <p>clientes@polipack.com.mx</p>
            </div>
          </a>
          <div className="info-item">
            <img src="/img/Location.svg" />
            <p>
              Justo Sierra 815 Col. Agua Blanca Industrial, Zapopan, Jalisco
            </p>
          </div>
        </div>
        <div className="contact-footer">
          <a href="mailto:clientes@polipack.com.mx" target="_blank">
            <img src="/img/EmailBorder.svg"></img>
          </a>
          <a href="https://wa.me/523313671572" target="_blank">
            <img src="/img/WhatsAppBorder.svg"></img>
          </a>
        </div>
      </div>

      <div
        className={
          myWindow > 739 ? "remove-display" : "set-display mobile-blue-footer"
        }
      >
        <div className="mobile-links-footer">
          <a href="/#">HOME</a>
          <a href="/#servicios">SERVICIOS</a>
          <a href="/#contacto">CONTACTO</a>
        </div>

        <div className="mobile-info-footer">
          <div className="mobile-info-item">
            <img src="/img/PhoneNoBorder.svg" />
            <p>33 1367 1572</p>
          </div>
          <div className="mobile-info-item">
            <img src="/img/EmailNoBorder.svg" />
            <p>clientes@polipack.com.mx</p>
          </div>
          <div className="mobile-info-item">
            <img src="/img/Location.svg" />
            <p>
              Justo Sierra 815 Col. Agua Blanca Industrial, Zapopan, Jalisco
            </p>
          </div>
        </div>

        <div className="mobile-contact-footer">
          <a href="mailto:clientes@polipack.com.mx" target="_blank">
            <img src="/img/EmailBorder.svg"></img>
          </a>
          <a href="https://wa.me/523313671572" target="_blank">
            <img src="/img/WhatsAppBorder.svg"></img>
          </a>
        </div>
      </div>

      <div className="email-links">
        <a href="https://secure.domain.com/secure/login.bml" target="_blank" ><p>Administración  </p></a>
        <a href="https://emailmg.domain.com/roundcube/" target="_blank" ><p>Usuarios</p></a>
      </div>

      <div className="white-footer">
        <img src="/img/Asset 24.svg" />
        <div className="vertical-separator"></div>
        <p>POLIPACK 2020 TODOS LOS DERECHOS RESERVADOS</p>
      </div>
    </div>
  )
}
